export default {
  name: "addCarModal",
  props: {
    p_obj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      addCarModalShow: false
    };
  },
  methods: {
    closeModal: function closeModal() {
      this.addCarModalShow = false;
    },
    showModal: function showModal() {
      this.addCarModalShow = true;
    }
  }
};