var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-modal', {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "dialog-inner"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('span', {
          staticClass: "text"
        }, [_vm._v("提示")]), _c('span', {
          staticClass: "close",
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v("✕")])]), _c('div', {
          staticClass: "content"
        }, [_c('p', [_vm._v(_vm._s(_vm.p_obj.message))]), _c('p', {
          staticClass: "p-name"
        }, [_vm._v("【 " + _vm._s(_vm.p_obj.productName) + "】不可网络销售")]), _c('p', [_vm._v("请联系开票员进行下单")]), _c('p', {
          staticClass: "phone"
        }, [_vm._v(_vm._s(_vm.p_obj.contactNumber ? _vm.p_obj.contactNumber : '023-8557488'))])])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.addCarModalShow,
      callback: function callback($$v) {
        _vm.addCarModalShow = $$v;
      },
      expression: "addCarModalShow"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };